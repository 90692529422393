.dropdown_menu,.dropdown_menu_sub{
list-style:none;
padding: 0;
margin: 0;
}
.dropdown_menu>li{
    font-weight: bold;
}
.dropdown_menu_sub > li{
    width:100%;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    font-weight:200;
    width:100%
}
.dropdown_menu_sub > li.selected{
    background-color: #DEDEDE;
}
.dropdown_menu_sub > li:hover{
    background-color: #DEDEDE;
    width:100% !important
}
.clear_filter{
    border-top: 1px solid #DEDEDE;
    cursor: pointer;
    font-size: 0.75rem;
    text-decoration: underline;
}
.clear_filter > span{
    cursor: pointer;
}
.clear_filter:hover{
    background-color: #DEDEDE;
}