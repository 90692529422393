.adtnl-message{
    color:#00008b !important;
}
@media only screen and (min-width:820px) and (max-width:1280px){
    .adtnl-message{
        bottom: -1.45rem !important;
    }
}
@media only screen and (min-width:576px) and (max-width:649px){
    .adtnl-message{
        bottom: -1.45rem !important;
    }
}
@media only screen and (min-width:769px) and (max-width:820px){
    .adtnl-message{
        bottom: -1.45rem !important;
    }
}