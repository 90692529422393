.react-bootstrap-table table {
  font-size: 13px;
  background-color: white;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  overflow: auto;
table-layout: auto !important;
  /* font-family: Roboto,sans-serif; */
}
.overflow {
  overflow: hidden;
}
.align{
  text-align:center;
}
.custom-datatable
{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.custom-datatable .table thead th {
  vertical-align: top !important;
  font-size: 13px;
  /* background-image: linear-gradient(rgb(243, 241, 241), white, rgb(238, 236, 236)); */
  /* background-image: linear-gradient(rgb(93 93 93), #737373, rgb(96 96 96)); */
  /* background: #66767b; */
  background-color: #FFFFFF !important;
  color: #000 !important;
  font-weight: bold;
  /* color:#ffffff; */
  min-width: unset !important;
  position: relative;
  padding-bottom: 1rem;
}
.custom-datatable .table thead th:has(label.filter-label){
  padding-bottom: 3rem;
}
.page-item .page-link {
  color: var(--theme-primary) !important;
}

.page-item.active .page-link {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
  color: white !important;
  outline: none;
}

.react-bootstrap-table table > tbody > tr {
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}

.custom-datatable.react-bootstrap-table table > tbody > tr:hover {
  color: unset !important;
}

.react-bootstrap-table table > tbody > tr:last-child {
  border-bottom: 1px solid #dee2e6;
}
.card .table td button {
  text-align: left;
}
.headerHeight{
  height: 121px;
}
.wrapClass {
  overflow: hidden;
  max-width: 22ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.width-50{
  width: 50px !important;
}
.width-60{
  width: 60px !important;
}
.width-75{
  width: 75px !important;
}
.width-90{
  width: 90px !important;
}
.width-100{
  width: 100px !important;
}
.width-115{
  width: 115px !important;
}

.width-130{
  width: 130px !important;
}

.width-240{
  width: 240px !important;
}

.card .table td {
  padding: .3rem .7rem;
   vertical-align:middle !important;
}
.card .table th {
  padding: .4rem .7rem;
   vertical-align:middle !important;
}

.react-bs-table-csv-btn {
	cursor: pointer !important;
	background-color:#de3d00 !important;
	color: #fff !important;
  font-weight: 600;
  vertical-align: top;
}

.react-bs-table-csv-btn:hover {
	color: #fff!important;
	background-color: #0ab3f3 !important;
	border-color: #0ab3f3 !important;
}

.react-bs-table-csv-btn:hover i {
	color: #fff!important;
}
.react-bs-table-csv-btn i {
  color: #fff !important;
  font-size: 1rem !important;
  margin-right: 4px !important;
  vertical-align: bottom!important;
  display: inline-block;
}
/* input.filter.text-filter {
    padding: 2px 4px;
    height: 32px;
    font-size: .8rem;
    border-radius: 2px;
    margin-top: 3px;
    width: 100%;
} */
input.filter.text-filter,select.filter.select-filter {
    padding: 2px 4px;
    height: 32px;
    font-size: .7rem;
    border-radius: 0px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-top: 3px;
    width: 90%;
    position: absolute;
    bottom: 2px;
    left: .5rem;
    margin-top: 2px;

}
.margin-bottom{
  margin-bottom: 5px;
}
.custom-datatable + .react-bootstrap-table-pagination{
 margin-top: 1rem !important;
}
.custom-datatable .filter{
  /* display:none; */
}
 .title-lg{
  font-size: 1.3rem;
}
.react-bootstrap-table-editing-cell{
  padding:0 !important;
}
.react-bootstrap-table-editing-cell input{
  max-width:100% !important;
  height:50px !important;
}


.fullscroll.react-bootstrap-table.table-responsive {
  overflow: auto;
  max-height: 60vH;
}
.fullscroll thead{
  position: sticky;
  top: -1px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.react-bootstrap-table .row-expansion-style{
  background-color: #FFF !important;
}