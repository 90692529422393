.switch-view {
  width: min-content;
  border: 1px solid rgb(235 235 235);
}
.switch-view-item {
  color: black;
  border: 0;
  
}
.active.switch-view-item {
  background-color: rgb(235 235 235);
  color: black;
  border: 0;
}
.switch-view-item:hover {
  background-color: rgb(235 235 235);
  border: 0;
  cursor: pointer !important;
}
