@media print {
    .accordion .card .collapse:not(.show)  {
         display : block
    }

    .hidePrint.row {
        display: none;
    }
    textarea + div {
        display: block !important;
        border: lightgray 1px solid;
        padding: 6px;
        min-height: 28px;
    }    
    .rdw-editor-toolbar {
        display: none !important;
    }
    .textarea-input.shadow-none{
        display : none;
    }
    div.form-group {
        page-break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
        display: block;
        position: relative;
    }
    .col-sm-6 {
        display: inline-block;
        position: relative;
        vertical-align: top;
        max-width:  50%;
        min-width:  50%;
    }
    .row {
        display: table;
        min-width: 100%;
        overflow-wrap: anywhere;
    }
    
    [class*="placeholder"],::placeholder,input[value=""]::-webkit-datetime-edit {
        color: transparent !important;
    }
    @page {
        margin-left: 0.5in;
        margin-right: 0.5in;
        margin-top: 0;
        margin-bottom: 0.9in;
     }     
}