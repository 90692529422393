.wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff30;
    left:0;
    top:0;
}
.message_view{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: .30rem;
   max-width: 600px;
}
.title{
    font-size: 1.5rem;
    font-weight: 500;
    width:100%;
    text-align: center;
    margin-bottom: 1rem;
}
.body{
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width:100%;
    text-align: center;
    font-weight: bold;
    
}
.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}