.top-nav {
  background-color:white;
  padding: 0px !important;
}

#top-nav-collapse > .navbar-nav {
  font-family: Roboto,sans-serif, Arial;
  margin-left: 30px;
  font-size: 14px;
}

#top-nav-collapse > .navbar-nav > a.nav-link {
  color:black;
  margin: 0px;  
  padding: 14px 15px 14px 10px
  }

#top-nav-collapse > .navbar-nav > a.nav-link:hover{background-color:white;}

#top-nav-collapse > .navbar-nav > a.nav-link.active {
  border-bottom: 3px solid var(--theme-secondary);
  background:white;
}

.top-nav > a.navbar-brand {
  margin-left: 10px;
  margin-top: 5px;
}
.dropdown_nav {
  padding-top:5px;
  color: white !important;
}

.navbar-brand{
margin-right: 0;
}
.navbar-nav .dropdown-menu {
  
  z-index: 1032;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.IIR-logo{
  font-weight: lighter;
  display: inline;
  margin-left: 4px;
  font-size: 1.20rem;
  border-left: 1px solid black;
    padding: 10px;
    margin-left: 12px;
}

.existing{margin:5px 0 !important; font-size: 15px;}

.existing .dropdown-item{
  padding: .25rem .5rem;
  font-size: 15px;
}

.existing .dropdown-item a{
  color: var(--theme-primary) !important;
  margin: 20x 0;
  padding: 5px;
}

#nav-dropdown{
  padding: .5rem 1rem;
  color:black;
}

.username{
  line-height: 1.2;
  padding: 7px 20px !important;
}

.username small{ font-size: 12px; float: right;}

.inpage-content .col-sm-6{margin-bottom:0px;}

@media screen and (max-width: 1200px) {
  div.content.container-fluid {
    margin-top: 75px;
  }
}
@media screen and (max-width: 767px) {
  div.content.container-fluid {
    margin-top: 45px;
  }
}