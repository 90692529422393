.new-flow-modal .dropdown-input,.new-flow-modal input[type="text"]
{
    width: 95%;
    margin-left: 2%;
}
.new-flow-modal .select-search__value .select-search__input,
.new-flow-modal .dropdown-input,.new-flow-modal input[type="text"]
{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    
    border-radius: 0.25rem 0.25rem 0 0 !important;
}
.new-flow-modal textarea,.new-flow-modal .dropdown-input,
.new-flow-modal input[type="text"],
.new-flow-modal .select-search__value .select-search__input
{
    background-color: #DEDEDE !important;
}
.md-xl{
    max-width: 600px !important;
}
.new-flow-modal input.template-name-input[type="text"]{
    background-color: #FFF !important;
    border:0 !important;
    border-radius:0 !important;
    
}

.new-flow-modal input.template-name-input[type="text"]:focus {
    
    outline: 0;
    box-shadow: unset !important;
}

.new-flow-modal ul.template-list{
list-style: none;
width: 100%;
}
.new-flow-modal ul.template-list li{
    width:100%;
    cursor:pointer;
}
.new-flow-modal ul.template-list li:hover{
background-color: #DEDEDE;
}