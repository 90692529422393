
.custom-range::-webkit-slider-thumb {
    background: #009688;
    margin-top: -0.30rem;
  }
  
  .custom-range::-moz-range-thumb {
    background: #009688;
    margin-top: -0.30rem;
  }
  
  .custom-range::-ms-thumb {
    background: #009688;
    margin-top: -0.30rem;
  }

  .custom-range::-webkit-slider-runnable-track{
    background: #009688;
    height:.25rem;
  }
  .custom-range::-moz-range-track{
    background: #009688;
    height:.25rem;
  }
  .custom-range::-ms-track{
    background: #009688;
    height:.25rem;
  }