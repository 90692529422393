@import "./components/common/datatable/datatable.css";
@import "./components//common//topnav/topnav.css";
@import "./components/common/input-fields/inputFields.css";
@import "./components/common/sticky-nav/sticky-nav.css";
@import "./components/common/text-editor/text-editor.css";
@import "./components/common/input-fields/searchableSelect.css";

:root {
   --theme-primary: #0ab3f3;
  /* --theme-primary: #0fa5de; */
  --theme-secondary: #de3d00;
}
.react-datepicker-wrapper {
  width: 100%;
}
.submissiontable .col-md-12 .react-bootstrap-table.table-responsive {
  overflow: auto;
  max-height: 60vH;
}
thead{
  position: sticky;
  top: 0;
}
.dd-menu-dark .dropdown-item:hover{
  color: #000 !important;
  }
/* :root {
  --theme-primary: #263F6A;
  --theme-secondary: #6A5126;
} */

/* :root {
  --theme-primary: #00AF3F;
  --theme-secondary: #6d6c6c;
}


:root {
  --theme-primary: #ff9352;
  --theme-secondary: grey  ;
} */


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif, Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  font-size: 14px !important; 
}
.react-datepicker__triangle{
  left:1rem !important;
}
.content {
  margin-top: 45px;
  padding-top: 30px;
}

button#pageDropDown {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary);
  padding: .175rem .55rem;
}

.record_view_dropdown > button {
  border: 1px solid var(--theme-secondary);
  color: var(--theme-secondary);
  border-radius: 4px;
  background-color: white;
}

.accordion > .card {
  margin-bottom: 7px;
  border-radius: 3px;
  overflow: visible;
}
.accordion > .card-body{
  overflow: visible;
}
.accordion > .card > .card-header {
  background-color: white !important;
  margin-bottom: 0px !important;
  font-weight: bold;
  font-size: 16px;
  color:rgb(90 90 90);
}

.accordion > .card > .card-header:before {
  content: "\25bc";
  font-size: 12px;
  margin-top: 5px;
  float: right;
  transition: all 0.5s;
}

.collapse.show + .card-header:before {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
} 

.accordion > .card > .card-header:hover {
  color: var(--theme-primary);
}

.table-top {
  margin-top: 10px;
  margin-left: 5px;
}

.data-cards {
  margin-bottom: 30px;
}

.data-cards > .card-body {
  padding-top: 0px;
}

.data-cards > .card-body > .tab-content {
  padding: 5px;
  margin-top: 40px;
}

.data-cards > .card-body > .nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: bold;
  font-size: 13px;
}

.data-cards > .card-body > .nav-tabs > .nav-link {
  color: var(--theme-primary);
  border:none;
}

.data-cards > .card-body > .nav-tabs > .nav-link.active {
  border: none;
  border-bottom: 3px solid var(--theme-secondary);
}

.form-page {
  background-color: white;
  border: 2px solid #dee2e6;
  padding: 30px !important;
  border-radius: 3px;
}

.alert-head {
  padding-left: 0px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  padding-bottom: 0px !important;
}

.alert-head .alert-heading {
  color: black !important
}

.create-button {
  background-color: #f5f5f5 !important;
  border: 1px solid #d0d0d0 !important;
  color: black !important;
}

.inpage-content {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #00000020;
}

footer {
  background-color: #ffffff;
  border-top:3px solid #696969;
  margin-top: 30px;
  width: 100%;
  box-shadow: 0px 0px 1px grey;
  padding: 12px;
  font-size: 13px;
}

footer p{
  color:rgb(48, 48, 48) !important;
  font-weight: 12px;
}

.back-button {
  font-weight: 500;
  color: var(--theme-primary);
  cursor: pointer;
  font-size: 18px;
}

.back-button > i {
  font-style: normal !important;
}

.new-button {
  color: black !important;
  font-size: 14px !important;
  border:none;
  margin: 6px 0;
  padding: 2px 10px !important;
    float: right;
    background: white;
    box-shadow: 0px 0px 10px 0px lightgray;
    border:1px solid gray;
}

.new-button:hover {
  color: black !important;
  font-size: 14px !important;
  border:none;
  margin: 6px 0;
  padding: 2px 10px !important;
    float: right;
    background: white;
    box-shadow: 0px 0px 10px 0px lightgray;
    border:1px solid gray;
}
.new-button::selection {
  color: black !important;
  font-size: 14px !important;
  border:none;
  margin: 6px 0;
  padding: 2px 10px !important;
    float: right;
    background: white;
    box-shadow: 0px 0px 10px 0px lightgray;
    border:1px solid gray;
}

.new-button:disabled {
  font-size: 14px !important;
  border:none;
  padding-top: 2px !important;
  margin: 6px 0;
  background-color: #c7c4c4 !important;
  cursor: not-allowed;
  float: right;
  box-shadow: 0px 0px 10px 0px lightgray;
}

.new-button::selection{
  color: black !important;
  font-size: 16px !important;
  border:none;
  padding-top: 2px !important;
  margin:0px;
    float: right;
    background: white;
    box-shadow: 0px 0px 10px 0px lightgray;
}

.new-button:active{
  color: black !important;
  font-size: 16px !important;
  border:none;
  padding-top: 2px !important;
  margin:0px;
    float: right;
    background: white;
    box-shadow: 0px 0px 10px 0px lightgray;
}


.new-button-link {
  color: var(--theme-secondary);
  text-decoration: none !important;
}
.disabled-link{
  pointer-events: none;
  color: gray;
}
.btn-primary {
  background-color: var(--theme-primary) !important;
  border: var(--theme-primary) !important;
}

.custom-file-input {
  z-index: 9;
}

.navbar{
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 20%)
}

.card, .inpage-content{
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 20%)
}
.button-nav {
  border-radius: 25px 25px !important;
  box-shadow:none;
  padding: 5px 15px 5px 15px !important;
}
.sticky-nav .nav-link {
  background-color: #de3d00;
  color: white !important;
  font-weight: normal;
  border-radius: 25px 25px !important;
  box-shadow:none;
  padding: 5px 15px 5px 15px !important;
}

.sticky-nav .nav-link a{
  color: rgb(90 90 90);
  font-weight: bold;
}

.alert-secondary{
 background-color: #ffffff !important;
 border-top: none !important;
 border-left: none !important;
 border-right: none !important;
 margin: 20px 0 !important;
 display: block;
 float: left;
 width: 100%;
 border-bottom: 3px solid #de3d00 !important;
}

.col-sm-6 {
  margin-bottom:15px;
}

.react-bootstrap-table-pagination button{
  padding: .275rem .55rem;
}

.react-bootstrap-table-pagination a{
  padding: .275rem .75rem;
}

.btn{
  font-size: 	0.875rem !important;
}

.card-body .nav-link{
  padding: .5rem .730rem !important;
}

.card{
  border:none !important
}
.error_milestones{
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.025) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.025) 50%, rgba(0, 0, 0, 0.025) 75%, transparent 75%, transparent);
    background-size: 64px 64px;
    background-color: rgb(254, 147, 57);
    color: rgb(8, 7, 7);
    width: 95%;
    margin: 10px auto;
    padding: 20px;
    font-size: 1.7rem;
    text-align: center;
}
.noDisplay{
  display:none;
}
.overflow {
  overflow-x: hidden;
}
.dashboard-main .data-cards {
  border: .5px solid #de3d00!important;
  box-shadow: none!important;
  width: 90%;
  margin: 1rem auto 1rem;
  }
.dashboard-main .data-cards:hover{box-shadow: 0 2px 15px 0 #de3d00 !important;}

.dashboard-main a{ color:#de3d00; width:100%;
}
.dropdowntoggle { 
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-item.active, .dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: white;
}
.dashboard-main p {


  font-size: 1.8rem!important; padding: 3.2rem 0 1.7rem; width: 100%; text-align: center;
  
  
  }

  .filterRowList .bi.bi-x-circle-fill{
    font-size: 25px;
    cursor: pointer;
  }

  .filterRowList .form-group .bold-label{
    display: none;
  }
  .createViewModal .modal-dialog{
    max-width: 1120px;
  }
  .filterRowList > .col > .row:last-child > .col:nth-child(4) .form-group{
    display: none;
  }

  .public-DraftEditor-content[contenteditable=true] {
    word-break: break-all;
}

.bg-grey-edit{
  background-color: #EEEEEE;
  border: .5px solid #DEDEDE;
}
.custom-creatable-pill{
  background-color: #DEDEDE;
  cursor: pointer;
}
.custom-creatable-pill:hover{
  background-color: #EEEEEE;
}