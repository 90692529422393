.wrapperContaniner{
   
    width:100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    max-height: 300px;
}
.reviewWrapper{
    display: flex;
    flex-wrap: wrap;
    width:100%;
    padding: 0;
    margin: 0;
    padding-bottom: .5rem;
}
.reviewerBlock {
    border-radius: .35rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    padding: 0.25rem;
    max-width: calc(50% - .90rem);
    min-width: calc(50% - .90rem);
    margin: .45rem;
    user-select: none;
}
.reviewerBlockSelection{
    background-color: #9e9e9e;
}
.reviewerBlockItem {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: .75rem;
    cursor: pointer;
}
.reviewIconArea{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.reviewerBlockItemRow {
    width:100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.reviewerBlockItemRow > div {
    flex-basis: 50%;
    padding: 0.25rem;
}


.wrapperContaniner::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .wrapperContaniner::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .wrapperContaniner::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .wrapperContaniner::-webkit-scrollbar-thumb:hover {
    background: #555;
  }