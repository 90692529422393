.steps_ul
{
   list-style:none;
   margin: 0;
   padding: 0;
   display: flex;
}
.steps_ul li{
   position: relative;
   width: 300px;
}
.steps_ul li>div{
  width: 200px;
  max-width: 200px;
  margin-left: calc(-100px - .85rem);
  padding-top: 1rem;
  text-align: center;
}
.steps_ul li.active>div{
   font-weight: bold;
}
.steps_ul li::before,.steps_ul li::after{
   content: ' ';
   display: inline-block;
   background-color: gray;
  
   position: absolute; 
}
.steps_ul li.active::before{
   background-color: black;
}
.steps_ul li::before{   
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      left: -1.2rem;
}
.steps_ul li:last-child::after{
   display: none;
}
.steps_ul li::after{  
   height: 0.125rem;
   width:100%;
   top:0.5rem;
   left: -0.2rem;
}