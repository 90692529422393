

.review_module ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .review_module  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .review_module  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .review_module  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
