
  /* ----- IMPORT ROBOTO FONT ----- */
/* @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,100);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons); */

body {
	/* font-family: 'Roboto', sans-serif; */
	/* background-color: #f5f5f5; */
}

/* ******************************
RIPPLES EFFECT
****************************** */
    .ripples {
        overflow: hidden;
        position: relative;
    }
    
    .waves {
        position: absolute;
        display: block;
        border-radius: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        -webkit-transform: scale(0);
           -moz-transform: scale(0);
             -o-transform: scale(0);
                transform: scale(0);
    }

    .ripple {
        -webkit-animation: ripple 0.65s linear;
           -moz-animation: ripple 0.65s linear;
            -ms-animation: ripple 0.65s linear;
             -o-animation: ripple 0.65s linear;
                animation: ripple 0.65s linear;
    }

    @-webkit-keyframes ripple {
        100% {
            opacity: 0;
            -webkit-transform: scale(2.5);
        }
    }
    
    @-moz-keyframes ripple {
        100% {
            opacity: 0;
            -moz-transform: scale(2.5);
        }
    }
    
    @-o-keyframes ripple {
        100% {
            opacity: 0;
            -o-transform: scale(2.5);
        }
    }
    
    @keyframes ripple {
        100% {
            opacity: 0;
            transform: scale(2.5);
        }
    }

/* ******************************
BUTTONS
****************************** */
.btn {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    /* z-index: 10; */
    display: inline-block;
    
    /* height: 30px;
    line-height: 30px; */
    vertical-align: middle;
    border: none;
    padding: .3rem 0.3rem;
    white-space: nowrap;
    /* letter-spacing: 1px; */
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    /* font-weight: 400; */
    font-size: .75rem;
    color: #212121;
    background-image: none;
    background-color: #E0E0E0;
    
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;

    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;

    -webkit-transition: all 0.2s ease;
       -moz-transition: all 0.2s ease;
         -o-transition: all 0.2s ease;
            transition: all 0.2s ease;

    -ms-touch-action: manipulation;
        touch-action: manipulation;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.btn_h-md{
    height:42px !important;
}
.btn_w-md{
    width:120px !important;
}
/* ----- BUTTON STATES ----- */
    .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn.active.focus {
        outline: none;
    }

    .btn:hover,
    .btn:focus,
    .btn.focus {
        color: #212121;
        text-decoration: none;
    }

    .btn:active,
    .btn.active {
        outline: 0;
        background-image: none;
    }

    .btn.disabled,
    .btn[disabled],
    fieldset[disabled] .btn {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
        filter: alpha(opacity=50);
        background-color: #DEDEDE;
    }

/* ----- ALTERNATE OPTIONS ----- */
/* PRIMARY */
    .btn_primary {
        color: #ffffff;
        background-color: #3F51B5;
    }

    .btn_primary:hover,
    .btn_primary:focus,
    .btn_primary.focus,
    .btn_primary:active,
    .btn_primary.active {
        color: #ffffff;
        background-color: #3949AB;
    }

/* ACCENT */
    .btn_accent {
        color: #000;
        background-color: #9ccfef;
    }

    .btn_accent:hover,
    .btn_accent:focus,
    .btn_accent.focus,
    .btn_accent:active,
    .btn_accent.active {
        color: #000;
        background-color: #a9d3ee;
    }
/* danger */
.btn_danger {
    color: #000;
    background-color: #d67976;
}

.btn_danger:hover{
    color: #000;
    background-color: #f5a6a4;
}
.btn_danger:focus,
.btn_danger.focus,
.btn_danger:active,
.btn_danger.active {
    color: #000;
    background-color: #d67976;
}
.btn_w-100px{
    min-width: 100px !important;
}
/* SUCCESS */
    .btn_success {
        color: #000;
        background-color: #8fc48e;
    }

    .btn_success:hover{
        color: #000;
        background-color: #bdf8bca8;
    }
    .btn_success:focus,
    .btn_success.focus,
    .btn_success:active,
    .btn_success.active {
        color: #000;
        background-color: #8fc48e;
    }
/* waring */
.btn_warning {
    color: #000;
    background-color: #ffb74d;
}

.btn_warning:hover,
.btn_warning:focus,
.btn_warning.focus,
.btn_warning:active,
.btn_warning.active {
    color: #000;
    background-color: #f8b95a;
}

/* ----- RAISED ----- */
    .btn_raised {
        -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
           -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
    }

/* ----- FLAT BUTTONS ----- */
    .btn_flat {
        font-weight: 400;
        color: #212121;
        background-color: transparent;
    }

    .btn_flat .waves {
        background-color: rgba(33, 33, 33, 0.1);
    }

    .btn_flat:hover,
    .btn_flat:focus,
    .btn_flat.focus,
    .btn_flat:active,
    .btn_flat.active {
        color: #212121;
    }

/* FLAT PRIMARY */
    .btn_flat.primary {
        color: #3F51B5;
    }

    .btn_flat.primary .waves {
        background-color: rgba(63, 81, 181, 0.3);
    }

/* FLAT ACCENT */
    .btn_flat.accent {
        color: #EC407A;
    }

    .btn_flat.accent .waves {
        background-color: rgba(255, 64, 129, 0.3);
    }

/* FLAT SUCCESS */
    .btn_flat.success {
        color: #009688;
    }

    .btn_flat.success .waves {
        background-color: rgba(0, 150, 136, 0.3);
    }

/* ----- ACTION ----- */
    .btn_action {
        min-width: 40px;
        width: 40px;
        height: 40px;
        line-height: 55px;
        padding: 0;
        border-radius: 20px;
    }

    .btn_action-lg {
        min-width: 56px;
        width: 56px;
        height: 56px;
        line-height: 84px;
        padding: 0;
        border-radius: 28px;
    }

    .btn_action-lg .material-icons {
        font-size: 36px;
    }

    .btn_action .waves,
    .btn_action-lg .waves {
        display: none;
    }