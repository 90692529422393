.sticky-nav {
  background-color: white !important;
  margin-bottom: 15px;
}

.scrolled {
  width: 97.8%;
  position: fixed;
  top: 57px;
  z-index: 1028;
  background-color: #e5f8ff !important;
  border:1px solid #c1daf1;
}

.navbar-secondary > a.navbar-brand {
    color: black !important;
}

.sticky-nav .nav-link {
  box-shadow: 0px 0px 3px #979797;
  color: black !important;
  font-weight: 500;
  padding: 5px 20px !important;
  margin-left: 10px;
  border-radius: 0px !important;
}
