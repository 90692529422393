.bg_avatar{
    background-color: #d7ccc8;
}
.comment_item{
    color:#000;
}
.comment_item:hover{
    background-color: #e0e0e0;
}
.comment_item_info > * {
  margin: 0!important;
  padding: 0 !important;
  max-width: 100%;
  word-break: break-all;
}
.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    border-radius: 2px;
    background: #fefefe;
    padding: 0.5rem;
  }
  
  .editor :global(.public-DraftEditor-content) {
    min-height: 30px;
    max-height: 30px;
    overflow-y: auto;
  }
  .editor :global(.public-DraftStyleDefault-block){
    margin: unset;
  }