.new-flow-modal .dropdown-input,.new-flow-modal input[type="text"]
{
    width: 95%;
    margin-left: 2%;
}
.react-datepicker-popper {
    z-index: 100 !important;
}
.new-flow-modal .select-search__value .select-search__input,
.new-flow-modal .css-yk16xz-control ,
.new-flow-modal .dropdown-input,.new-flow-modal input[type="text"],.new-flow-modal input[type="number"],
.new-flow-modal .react-datepicker-wrapper>.react-datepicker__input-container>button
{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    
    border-radius: 0.25rem 0.25rem 0 0 !important;
}

.alert-line{
    background-color: #ffffff !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    margin: 20px 0 !important;
    display: block;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.150) !important;
   }
.new-flow-modal textarea,.new-flow-modal .dropdown-input,
.new-flow-modal input[type="text"],
.new-flow-modal .css-yk16xz-control ,
.new-flow-modal .select-search__value .select-search__input,
.new-flow-modal input[type="number"],
.new-flow-modal .react-datepicker-wrapper>.react-datepicker__input-container>button
{
    background-color: #DEDEDE !important;
}
.new-flow-modal.disabled_view  textarea,
.new-flow-modal.disabled_view  .dropdown-input,
.new-flow-modal.disabled_view  input[type="text"],
.new-flow-modal.disabled_view  .css-yk16xz-control,
.new-flow-modal.disabled_view  .select-search__value .select-search__input,
.new-flow-modal.disabled_view  input[type="number"],
.new-flow-modal.disabled_view .react-datepicker-wrapper>.react-datepicker__input-container>button
{
    background-color: #FFF !important;
}
.modal-mdx .modal-dialog.modal-md{
max-width: 600px !important;
}
/* .new-flow-modal.modal-body{
    max-height:calc(100vh - 230px);
} */