.bottom-border-input {
  border: none !important;
  border-bottom: 2px solid #ced4da !important;
  width: 95% !important;
  outline: none !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  margin-left: 2%;
}
.dropdown-menu-ex {
  max-height: 360px;
  overflow-y: auto;
}
.bottom-border-input:disabled {
  background-color: #f5f5f5 !important;
}

.bottom-border-input:focus {
  border-bottom: 1px solid var(--theme-primary) !important;
  border-radius: 0px;
}
.textarea-input.shadow-none.showPrint{
  display: none;
}
textarea.form-control[name=SOSLHelpField]{
  height: 100px ; 
  font-size: 14px;
  }
  textarea.form-control[name=extUniqueSubmissionIdentifier]{
    height: 80px ; 
    font-size: 14px;
    }
  textarea.form-control[name=milestone_comments]{
    height: 100px ; 
    font-size: 14px;
    }
    textarea.form-control[name=milestone_comments_external]{
      height: 100px ; 
      font-size: 14px;
      }
      textarea.form-control[name=comments]{
        height: 100px ; 
        font-size: 14px;
        }
  textarea.form-control[name=conceptDescription]{
    height: 140px ; 
    font-size: 14px;
    }
   
    .createUpdateByfontsize{
      border: none !important;
      border-bottom: 2px solid #ced4da !important;
      width: 95% !important;
      outline: none !important;
      border-radius: 0px !important;
      font-size: 12px !important;
      margin-left: 2%;
      padding-right:0px
    }
    
    
.dropdown-input {
  width: 95%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 2%;
}

.dropdown-input > button:focus {
  border-bottom: 1px solid var(--theme-primary);
}

.dropdown-input > button:after {
  float: right;
  margin-top: 12px;
}


.dropdown-input > button {
  width: 100%;
  text-align: left;
}

.dropdown-input > .dropdown-menu.show {
  width: 100%;
}

.bold-label {
  font-size: 13px;
  font-weight: 500;
  width: 95%;
  padding-left: 2%;
}
.react-datepicker__header {
  background-color: white;
  margin: 2px;
  
  
}
.react-datepicker__header button,
.react-datepicker__header input,
.react-datepicker__header optgroup,
.react-datepicker__header select,
.react-datepicker__header textarea {
  font-size: 15px;
  margin: 0;
  background-color: white;
  font-family: inherit;
  /* font-size: inherit; */
  /* border: 1px solid; */
  line-height: inherit;
  margin: 2px;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  color: black;
  background-color: #dfdfdf;
  /* border: 1px solid #999; */
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px 4px 2px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";}
  

.textarea-input {
  width: 95% !important;
  margin-left: 2%;
  font-size: 14px;
}
.btn-primary {
  background-color:white !important;
}
.textarea-input:focus {  
  border: 1px solid #dee2e6 !important;
  border-bottom: 1px solid var(--theme-primary) !important;
}

.form-alert {
  padding: 7px !important;
  margin-top: 20px;
}

.custom-file {
  margin-left: 2%;
}

.tooltip-inner {
  max-width: 500px !important;
}
.rdw-editor-main {
  padding: 0 10px;
  }

  .custom-control.custom-switch{
    margin-left: 2%;
  }