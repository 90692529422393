.tabActive{
  background-color: rgb(235 235 235) !important;
}
.tabItem{
    user-select: none !important;
  
}
.tabItem:hover{  
 background-color: rgb(235 235 235) !important;
}

